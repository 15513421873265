import RouteWrapper from 'components/wrappers/RouteWrapper';
import { PATH } from 'constants/paths';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const DeliveryAddress = lazy(() => import('pages/DeliveryAddressPage'));
const BrokenUrlPage = lazy(() => import('pages/BrokenUrlPage'));
const ReportErrorGaro = lazy(() => import('pages/swedishForms/ReportErrorGaro'));
const SkanskaOrderChargeKeyForPrivateCar = lazy(() => import('pages/swedishForms/SkanskaOrderChargeKeyForPrivateCar'));
const OrderChargeKeyLeasingCar = lazy(() => import('pages/swedishForms/SkanskaOrderChargeKeyLeasingCar'));
const OrderChargeKeySkanskaCar = lazy(() => import('pages/swedishForms/SkanskaOrderChargeKeySkanskaCar'));
const SkanskaOrderChargeKeyUtilityVehicle = lazy(() => import('pages/swedishForms/SkanskaUtilityVehiclePage'));
const OrderWallbox = lazy(() => import('pages/swedishForms/SkanskaOrderWallbox'));
const HelsingborgshemPrivateCharging = lazy(() => import('pages/swedishForms/HelsingborgshemPrivateCharging'));
const ReportErrorKEBAForm = lazy(() => import('pages/swedishForms/ReportErrorKEBAForm'));
const RegisterMerHub = lazy(() => import('pages/swedishForms/RegisterMerHub'));
const Installation = lazy(() => import('pages/swedishForms/Installation'));
const ReimbursementForm = lazy(() => import('pages/swedishForms/Reimbursement'));
const ChargingPartner = lazy(() => import('pages/swedishForms/ChargingPartner'));
const CancelSubscription = lazy(() => import('pages/swedishForms/CancelSubscription'));

function AppRoutes(): React.JSX.Element {
    return (
        <Routes>
            <Route element={<RouteWrapper />}>
                <Route path={PATH.WILDCARD} element={<BrokenUrlPage />} />
                <Route path={PATH.SUBSCRIPTIONS_DELIVERY_ADDRESS} element={<DeliveryAddress />} />
                <Route path={PATH.CHARGING_PARTNER} element={<ChargingPartner />} />
            </Route>
            <Route element={<RouteWrapper showLanguageSelector={false} />}>
                <Route path={PATH.INSTALLATION_FORM} element={<Installation />} />
                <Route path={PATH.REPORT_ERROR_GARO} element={<ReportErrorGaro />} />
                <Route path={PATH.CANCEL_SUBSCRIPTION} element={<CancelSubscription />} />
                <Route path={PATH.ORDER_CHARGE_KEY_PRIVATE_CAR} element={<SkanskaOrderChargeKeyForPrivateCar />} />
                <Route path={PATH.ORDER_CHARGE_KEY_LEASING_CAR} element={<OrderChargeKeyLeasingCar />} />
                <Route path={PATH.ORDER_CHARGE_KEY_SKANSKA_CAR} element={<OrderChargeKeySkanskaCar />} />
                <Route path={PATH.ORDER_CHARGE_KEY_UTILITY_VEHICLE} element={<SkanskaOrderChargeKeyUtilityVehicle />} />
                <Route path={PATH.ORDER_WALLBOX} element={<OrderWallbox />} />
                <Route path={PATH.PRIVATE_CHARGING_HELSINGBORGSHEM} element={<HelsingborgshemPrivateCharging />} />
                <Route path={PATH.REPORT_KEBA_ERROR} element={<ReportErrorKEBAForm />} />
                <Route path={PATH.REGISTER_MER_HUB} element={<RegisterMerHub />} />
                <Route path={PATH.REIMBURSEMENT_FORM} element={<ReimbursementForm />} />
            </Route>
        </Routes>
    );
}

export default AppRoutes;
